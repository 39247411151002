<template>
  <div class="flex flex-row py-8 border-b border-gray-400 border-solid header-container" v-loading.fullscreen="loading">
    <div class="flex-grow items-center flex flex-row header-container__left">
      <div class="font-bold">
        <label>{{
          $t(`address_book.${mode === 'add' ? 'delivery_address_register' : 'delivery_address_edit'}`)
        }}</label>
      </div>
      <div class="ml-20">
        <el-button type="primary" class="btn-original rounded-full w-28" :disabled="isSaveDisabled" @click="onSave">{{
          $t('address_book.save')
        }}</el-button>
        <el-button
          v-if="mode === 'edit'"
          type="primary"
          class="rounded-full w-28"
          :disabled="isDeleteDisabled"
          @click="onDelete"
          >{{ $t('address_book.delete') }}</el-button
        >
      </div>
    </div>

    <div class="items-center header-container__right">
      <el-button type="primary" size="medium" @click="backToPrevious">
        {{ $t('address_book.delivery_destination_list') }}
      </el-button>
    </div>
  </div>

  <!-- address book table -->
  <div class="mt-16 common-form-portal 2xl:w-8/12 xl:w-11/12 lg:w-full">
    <el-form
      :rules="rules"
      :model="formModel"
      ref="addressForm"
      label-position="right"
      label-width="150px"
      class="form-container"
    >
      <div class="flex grid grid-cols-12 items-center gap-4 py-1">
        <div class="col-span-10">
          <el-form-item prop="companyName" :label="$t('Name')">
            <el-input v-model="formModel.companyName"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center gap-4 py-1">
        <div class="col-span-10">
          <el-form-item prop="country" :label="$t('address_book.country_region')">
            <el-select
              :placeholder="$t('barcode_type.select')"
              default-first-option
              filterable
              v-model="formModel.country"
              class="lg:w-1/2 md:w-full"
            >
              <el-option-group
                v-for="(group, groupKey) in countries"
                :key="groupKey"
                :label="$t(`countryGroup.${groupKey}`)"
              >
                <el-option
                  v-for="country in group"
                  :key="country"
                  :value="country"
                  :label="$t(`countryList.${country}`)"
                >
                  {{ $t(`countryList.${country}`) }}
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center gap-4 py-1">
        <div class="col-span-5">
          <el-form-item prop="postalCode" :label="$t('address_book.postal_code')">
            <el-input
              v-model="formModel.postalCode"
              :placeholder="masks.zipCode.replaceAll('#', '_')"
              v-maska="masks.zipCode"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-span-5">
          <el-form-item prop="prefecture" :label="$t('address_book.prefecture')">
            <el-input v-model="formModel.prefecture"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center gap-4 py-1">
        <div class="col-span-10">
          <el-form-item prop="address1" :label="$t('address_book.address_1')">
            <el-input v-model="formModel.address1"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center gap-4 py-1">
        <div class="col-span-10">
          <el-form-item prop="address2" :label="$t('address_book.address_2')">
            <el-input v-model="formModel.address2"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center gap-4 py-1">
        <div class="col-span-10">
          <el-form-item prop="phoneNumber" :label="$t('address_book.phone_number')">
            <el-input
              v-model="formModel.phoneNumber"
              :placeholder="masks.phoneNumber.replaceAll('#', '_')"
              v-maska="masks.phoneNumber"
            ></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script lang="ts">
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { FETCH_ADDRESSES, FETCH_COUNTRIES, SAVE_ADDRESSES } from '@/store/actions'
import { addAddress, deleteAddress, updateAddress } from '@/utils/api'
import { DEFAULT_ADDRESS, POPULAR_COUNTRIES } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/utils'
import { maska } from 'maska'
import { PHONE_MASK, ZIPCODE_MASK } from 'smartbarcode-web-core/src/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IAddress, IAddressResponse } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
@Options({
  components: {},
  directives: { maska },
  name: 'AddressBookDetail',
})
export default class AddressBookDetail extends mixins(ValidateForm) {
  @Prop({ type: String }) action!: string
  @Prop({ type: String }) address!: string
  loading = false
  countries: {
    popularCountries: string[]
    allCountries: string[]
  } = {
    popularCountries: [],
    allCountries: [],
  }

  formModel: IAddress = { ...DEFAULT_ADDRESS } as IAddress
  requiredMessage = ''
  mode: 'add' | 'edit' = 'add'
  selectedIndex = -1
  isSaveDisabled = false
  isDeleteDisabled = false
  get masks() {
    return {
      phoneNumber: PHONE_MASK[this.formModel.country] || PHONE_MASK.DEFAULT,
      zipCode: ZIPCODE_MASK[this.formModel.country] || '',
    }
  }

  backToPrevious() {
    this.$router.push({ name: 'address-book' })
  }

  displaySuccessMsg(msg: string) {
    openMessage(msg, 'success')
  }

  async doSave() {
    try {
      this.loading = true
      if (this.mode === 'add') {
        await addAddress(this.formModel)
        await this.fetchAddressList()
        this.displaySuccessMsg(this.$t('address_book.create_success'))
        this.backToPrevious()
      } else {
        await updateAddress(this.selectedIndex, this.formModel)
        await this.fetchAddressList()
        this.displaySuccessMsg(this.$t('address_book.update_success'))
        this.backToPrevious()
      }
    } catch (e) {
      errorHandler(e)
    } finally {
      this.loading = false
      this.isSaveDisabled = false
    }
  }

  async fetchAddressList() {
    await this.$store.dispatch(FETCH_ADDRESSES).then((res: IAddressResponse) => {
      res.addressBook.forEach((addr, index) => {
        addr.index = index
      })
      this.$store.commit(SAVE_ADDRESSES, res)
    })
  }

  onDelete() {
    this.$confirm(this.$t('address_book.confirm_delete'), this.$t('info'), {
      confirmButtonText: this.$t('address_book.delete'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(() => {
        this.loading = true
        this.isDeleteDisabled = true
        deleteAddress(this.selectedIndex)
          .then(() => {
            this.displaySuccessMsg(this.$t('address_book.delete_success'))
            this.backToPrevious()
          })
          .catch((err) => {
            errorHandler(err)
          })
          .finally(async () => {
            this.isDeleteDisabled = false
            this.fetchAddressList()
            this.loading = false
          })
      })
      .catch(() => true)
  }

  onSave() {
    this.isSaveDisabled = true
    this.$refs.addressForm.validate((valid: string) => {
      if (valid) {
        this.doSave()
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }

  formatAddressPhoneValidate = (rule: string, value: string, callback: Function) => {
    if (isEmpty(value)) {
      return callback(new Error(this.messages.required))
    }

    setTimeout(() => {
      let isValid = false
      if (this.masks.phoneNumber) {
        if (this.masks.phoneNumber.length === value.length) {
          isValid = true
        }
        if (this.masks.phoneNumber.length === PHONE_MASK.DEFAULT.length) {
          isValid = true
        }
      } else {
        if (value.length <= 11) {
          isValid = true
        }
      }
      if (isValid) {
        callback()
      } else {
        callback(new Error(this.messages.number))
      }
    }, 100)
  }

  rules = {
    companyName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    country: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    postalCode: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    prefecture: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    address1: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    phoneNumber: [{ required: true, validator: this.formatAddressPhoneValidate, trigger: 'blur' }],
  }

  async created() {
    this.loading = true
    const params = this.$route.params
    if (isEmpty(params)) {
      this.$router.push({ name: 'address-book' })
    }
    this.requiredMessage = this.$t('this_field_is_required')

    this.countries.popularCountries = { ...POPULAR_COUNTRIES }
    if (!this.$store.state.profile?.isLoadCountry) {
      this.$store.dispatch(FETCH_COUNTRIES).then(() => {
        this.countries.allCountries = this.$store.state.profile?.countries
      })
    } else {
      this.countries.allCountries = this.$store.state.profile?.countries
    }

    if (params.action === 'edit') {
      this.mode = 'edit'
      this.formModel = JSON.parse(params.address)
      this.selectedIndex = this.formModel.index ?? -1
    }
    this.loading = false
  }
}
</script>
